/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "dabed07b926c4f4fa907d505cb6cbdb7",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "dabed07b926c4f4fa907d505cb6cbdb7",
            "region": "us-east-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "alumni",
            "endpoint": "https://bfp3rn7hn0.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "alumninetwork",
            "endpoint": "https://4a9h28qovb.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "announcement",
            "endpoint": "https://ghanqf5kkd.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "event",
            "endpoint": "https://tw2qmdo5vf.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "notification",
            "endpoint": "https://vectzhl005.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "userpool",
            "endpoint": "https://t4f06o2zzj.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "userprofile",
            "endpoint": "https://nbx50aqj66.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://kgigyihtfzh3jet3zeppqiwuzm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:f0a2982e-f37e-417d-9748-0221d1b39f96",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Wnp8oUa2y",
    "aws_user_pools_web_client_id": "536bibtrd0tdd1r70p9gnnousu",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "BIRTHDATE",
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "AlumniNetworkDb-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "Announcements-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "Events-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "ParentsAlumniRel-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "UserProfilesDb-dev",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "bwssisdev076b4b2d0eec44f88091db05238941e9121724-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
