import "./App.css";
import { Amplify, Analytics, I18n } from "aws-amplify";
import {
  authenticatorComponents,
  authenticatorFormFields,
} from "./utils/amplifyCustomAuthenticatorSettings";
import { dict } from "./utils/internationalization";
import { useEffect } from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { useDispatch } from "react-redux";
import {
  hasAccessToAlumniDropdown,
  loadLoggedInUser,
} from "./store/slices/userSlice";
import awsExports from "./aws-exports";
import Loading from "./components/shared/Loading";
import AlertSnackbar from "./components/shared/AlertSnackbar";
import RouteConfig from "./routing/RouteConfig";
import "@aws-amplify/ui-react/styles.css";

/// Analytic service called `bwssisdevpinpoint` was added in order to set up an Amazon Pinpoint and leverage email notifications. 
/// By default, this service is enabled. It will remain disabled until required.
// Analytics.disable();

//Analytics Auto Tracking helps you to automatically track user behaviors like sessions start/stop, page view change and web events like clicking or mouseover.
/// By default, this feature is enabled. It will remain disabled until required.
// Note: this must be called before Amplify.configure() or Analytics.configure() to cancel the session_start event
Analytics.configure({
  autoSessionRecord: false,
});

Amplify.configure(awsExports);

//I18n configuration. also used to customize Amplify's authtenticator components
I18n.setLanguage("en");
I18n.putVocabularies(dict);

///signOut is a callback to handle a futre onClick event and log out user
function App({ signOut, user }) {
  const dispatch = useDispatch();

  useEffect(() => {
    //dispatch user logged in
    const user_info = {
      userName: user?.username,
      profileType: user?.signInUserSession?.idToken?.payload["cognito:groups"].slice(0, 1)[0], //set profileType based on highest precedence User Group
      firstName: user?.attributes?.given_name,
      lastName: user?.attributes?.family_name,
      email: user?.attributes?.email
    };

    dispatch(loadLoggedInUser(user_info));
    dispatch(hasAccessToAlumniDropdown(user_info.profileType));
  }, [user]);

  return (
    <>
      <AlertSnackbar />
      <Loading />      
      <RouteConfig signOut={signOut}/>
    </>
  );
}

export default withAuthenticator(App, {
  hideSignUp: true,
  formFields: authenticatorFormFields,
  components: authenticatorComponents,
});

// export default App;